@import "./_variables.scss";

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 5vh;
    padding: 0 3rem;
    & h1 {
        
        font-size: 1.75rem;
        font-weight: 700;
        margin: 1rem;
        font-family: 'Times New Roman', Times, serif;
        text-decoration: underline;
        text-align: left;
        width: 100%;
    }

    & p {
        text-indent: 1rem;
        font-size: 1rem;
        font-weight: 400;
        margin: 0.5rem;
        width: 100%;
    }
}

.contactContainer {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.disclaimer {
    margin-top: 100px;
    &>h1 {
        font-size: 1.25rem;
    }

    &>p {
        font-size: 0.75rem;
    }
}