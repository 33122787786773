@import "./_variables.scss";

.header {
    width: 100%;
    min-height: 10vh;

    position: relative;
    top: 0;
    left: 0;
    & .button {
        font-size: 1rem;
        padding: 0.4rem;
        border-radius: 10px;
        transition: background-color 0.2s ease-in-out;
        // transition: color 0.4s ease-in-out;
    }

    & .button:hover {
        background-color: $yellow;
        // color: white;
    }
}

.logo {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 700;
    font-size: 1.1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    &>img {
        display: inline;
        height: 20px;
        width: 20px;
        border-radius: 25px;
    }
}