@import "./_variables.scss";

.footer {
    width: 100%;
    min-height: 200px;
    background-color: $blue;
    position: relative;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;

    & .waves {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

}
