@import "./_variables.scss";

:root {
    background-color: $background;
}

.banner {
    padding: 0;
    min-height: 80vh;

    background-image: url("../assets/homepage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .bannerImage {
        height: 200px;
        width: 200px;

    }

    .bannerContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 70%;

        margin-bottom: 40px;
        
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
         
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.3);
        padding: 20px;
        color: white;

        & .bannerTitle {
            font-size: 2rem;
            font-weight: 600;
            margin: 0;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & .bannerSubtitle {
                font-size: 1rem;
                margin: 0;
            }
        }

         
    }
    & .waves {
        position: absolute;
        bottom: 0vh;
        left: 0;
        // width: 100%;        
        transform: translateY(30%);
    }  
}

.homeContainer {
    margin: 0;

    // min-height: 20vh;
    width: 100%;
    padding: 5px 20px 50px 20px;
    margin: auto;
    
    

    position: relative;
    top: 0;
    left: 0;
    
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &>hr {
        width: 90%;
        border-top: 2px solid $blue;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &>h1 {
        width: 100%;
        // margin: 20px;
        text-align: center;
        font-size: 1.75rem;
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }

    & .cardContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 300px 100px;
        justify-items: center;
        align-items: center;

        width: 80%;
        margin: 0;
        padding: 0;
        
        & .contact{
            // item spans 4 columns and 1 row
            grid-column: 1 / 5;
            grid-row: 2 / 3;

            width: 95%!important;
        }

        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 300px 300px;
        }
    }
}

