$background: #fff;
$foreground: #1e293b;

$red: #F19A9C;
$orange: #FFC29F;
$yellow: #FFFAAE;
$green: #CDECAD;
$blue: #A0CDED;
$violet: #AF8FC1;

.red {
    background-color: $red;
}
.orange {
    background-color: $orange;
}
.yellow {
    background-color: $yellow;
}
.green {
    background-color: $green;
}

.blue {
    background-color: $blue;
}

.violet {
    background-color: $violet;
}