@import "./_variables.scss";


.card {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    width: 200px;
    height: 200px;
    margin: 10px;
    padding: 0;

    // border-radius: 25px;
    border: 0px solid $blue;
    background-color: rgba($violet, 0.1);
    // background-color: #F3F4F5;
    box-shadow: 0 5px 5px $blue;

    transition: all 0.2s ease-in-out;

    & > .iconContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: auto;
        padding: 0;
    }

    & > .cardBody {
        position: relative;
        top: 0;
        left: 0;

        height: 45%;
        width: 100%;
        margin: 0;
        // border-radius:  0  0 25px 25px; 
        padding-top: 10px;

        color: white;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        
        background: rgb(100,236,218);
        background: linear-gradient(0deg, #e2d6c7 0%, rgba(160,205,237,1) 100%);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        & .wave {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-90%);
        }

    }

    &:hover {
        top: -10px;
        // left: -5px;
        // box-shadow: 0 10px 10px $blue;
    }

    
    & .cardTitle {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
        text-align: center;
        color: rgb(84, 122, 156);
    }

    & .cardSubtitle {
        font-size: 1rem;
        margin: 0;
        text-align: center;
    }
}

.fullBg {
    & .cardBody { 
        height: 100%;
        margin: 0;
    }

    & .iconContainer {
        display: none;
    }
}